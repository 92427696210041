:root {
    --aggablue: #00bbd0;
    --aggayellow: #ecb400;
    --aggabg: #141a1e;
    --aggabg2: rgb(240, 240, 240);
    --aggagrey: #394552;
}

html,
body {
    background-color: var(--aggabg2);
    box-sizing: border-box;
    overflow-x: hidden;
}

h1 {
    font-size: 22px;
}

h3 {
    font-size: 15px;
}
h4 {
    font-weight: normal;
}

p {
    color: #666666;
}

* {
    margin: 0;
    padding: 0;
}

header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: 0.5s;
    padding: 40px 0px;
    z-index: 5;
}

header .logo {
    position: relative;
    transition: 0.5s;
    justify-content: center;
    align-items: center;
    padding-left: 40px;
}

header .logo img {
    height: 25px;
    margin-top: 11px;
}

header ul {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 40px;
}

header ul li {
    position: relative;
    list-style: none;
}

header ul li a {
    position: relative;
    margin: 0 15px;
    text-decoration: none;
    color: #fff;
    letter-spacing: 2px;
    font-weight: 500px;
    transition: 0.5s;
}

header.sticky {
    padding: 20px 0px;
    background: var(--aggagrey);
    opacity: 0.98;
}

header.sticky ul li a {
    color: #ffffff;
}

.hero-container {
    height: 125vh;
    background-color: var(--aggabg);
    overflow: visible;
    justify-content: center;
    display: flex;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
}

#proc {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
}

#proc img {
    position: absolute;
    width: 800px;
    z-index: 2;
    top: 80vh;
    max-width: 90vw;
    transition: all 0.3s;
}

.hero2 {
    width: 30vw;
    min-width: 300px;
    align-self: center;
    margin-bottom: 200px;
}

.aggatech {
    margin-right: 300px;
}

#about {
    display: flex;
    flex-wrap: wrap;
    background-color: var(--aggabg2);
    overflow: hidden;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
}

.learn-more {
    display: flex;
    background-color: var(--aggayellow);
    width: 150px;
    padding: 10px;
    border-radius: 25px;
    position: absolute;
    bottom: -20px;
    right: 25px;
    box-shadow: 5px 10px 30px 8px rgba(0, 0, 0, 0.1);
    justify-content: center;
    transition: all 0.15s ease-in-out;
}

.learn-more:hover {
    transform: scale(1.1);
}

.learn-more h1 {
    color: #ffffff;
    font-size: 18px;
}

.learn-more a {
    text-decoration: none;
}

.arrow {
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-bottom: 68px;
    box-sizing: border-box;
    height: 40px;
    width: 40px;
    border-style: solid;
    border-color: white;
    border-width: 0px 2px 2px 0px;
    transform: rotate(45deg);
    z-index: 3;
}

.arrow:hover {
    border-bottom-width: 4px;
    border-right-width: 4px;
    color: #00bbd0;
}

.dashboard-icon {
    height: 30vw;
    /* padding-left: 50px;
    padding-right: 50px; */
    z-index: 3;
    min-height: 300px;
    padding: 25px;
}

.card-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    z-index: 3;
    transition: all 0.3s;
}

.card-container-2 {
    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: center;
    align-items: center;
    z-index: 3;
    transition: all 0.3s;
}

.card-container-2 img {
    padding-top: 40px;
}

.card-text {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 5px 10px 50px 8px rgba(0, 0, 0, 0.15);
    position: relative;
    z-index: 3;
    padding: 40px;
}

.team-container {
    background-color: var(--aggabg);
    min-height: 900px;
    clip-path: polygon(0 0, 100% 10%, 100% 100%, 0 90%);
    display: flex;
    flex-direction: column;
    padding-bottom: 180px;
}

.team-card {
    display: flex;
    flex-direction: column;
    width: 220px;
    height: 220px;
    overflow: hidden;
    box-shadow: 5px 10px 30px 8px rgba(0, 0, 0, 0.3);
}

.team-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 300px;
}

.team-cards-2 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 100px;
}

.team-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px 15px 0px 0px;
}

.team-title {
    display: flex;
    flex-direction: column;
    width: 220px;
    background-color: var(--aggagrey);
    height: 60px;
    border-radius: 0px 0px 15px 15px;
    justify-content: center;
    align-items: center;
}

.team-title h3 {
    color: var(--aggayellow);
    font-weight: 700;
    padding-top: 1px;
}

.team-title h4 {
    color: var(--aggabg2);
}

.team-card-base {
    padding: 30px;
}

#data {
    position: relative;
    top: 0;
    left: 0;
    transition: all 0.3s;
}

.data_image {
    position: absolute;
    width: 600px;
    z-index: 2;
    top: -110px;
    right: 25px;
    filter: brightness(94%);
    transition: all 0.3s;
}

#partners {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 75px;
}

#partners h1 {
    padding-bottom: 60px;
}

#partners img {
    height: 50px;
    padding-left: 25px;
    padding-right: 25px;
    padding-bottom: 30px;
}

.logo-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

#contact {
    display: flex;
    flex-wrap: wrap;
    background-color: var(--aggabg);
    padding: 100px;
    justify-content: center;
}

.details h3 {
    color: var(--aggabg2);
    font-weight: 500;
    align-self: center;
}

.form-container {
    display: flex;
    padding-top: 70px;
    padding-bottom: 70px;
    justify-content: center;
    flex-wrap: wrap;
    align-content: center;
}

.feedback-input {
    color: var(--aggabg2);
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 18px;
    border-radius: 5px;
    line-height: 22px;
    background-color: var(--aggagrey);
    border: 2px solid var(--aggagrey);
    transition: all 0.3s;
    padding: 13px;
    margin-bottom: 15px;
    width: 100%;
    box-sizing: border-box;
    outline: 0;
}

.feedback-input:focus {
    border: 2px solid var(--aggabg);
}

textarea {
    height: 250px;
    line-height: 150%;
    resize: vertical;
    background-color: var(--aggabg);

    color: var(--aggabg);
}

[type="submit"] {
    width: 100%;
    background: var(--aggayellow);
    border-radius: 25px;
    border: 0;
    cursor: pointer;
    color: white;
    font-size: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: all 0.3s;
    margin-top: -4px;
    font-weight: 700;
}
[type="submit"]:hover {
    background: var(--aggayellow);
}

.details {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.form {
    width: 350px;
    max-width: 500px;
    min-width: 200px;
    padding: 10px;
    padding-bottom: 30px;
}

/* FOOTER */

footer {
    padding-top: 40px;
    padding-bottom: 40px;
    color: #ffffff;
    background-color: #394552ff;
    font-size: 14px;
    text-align: center;
    position: relative;
}

footer a,
footer a:visited {
    color: #ffffff;
}
footer a:hover,
footer a:focus {
    color: #fff;
}

/* copyright */
footer .copyright {
    margin: 0;
    padding: 0;
}
footer .copyright li {
    display: inline-block;
    margin: 0;
    padding: 0;
    line-height: 24px;
}

/* Go To Top Button */
#go-top {
    position: absolute;
    top: -24px;
    left: 50%;
    margin-left: -30px;
}
#go-top a {
    text-decoration: none;
    border: 0 none;
    display: block;
    width: 50px;
    height: 50px;
    background-color: #525252;

    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

    color: #fff;
    font-size: 30px;
    line-height: 60px;
    border-radius: 100%;
    font-weight: 700;
}
#go-top a:hover {
    background-color: var(--aggayellow);
}

.map {
    display: flex;
    position: relative;
    width: 500px;
    height: 300px;
    margin: 50px;
}

.trade-hero-container {
    height: 40vh;
    background-color: var(--aggabg);
    overflow: visible;
    display: flex;
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
}

.trade-hero-container h2 {
    color: var(--aggabg2);
}

.trade-hero-container p {
    color: rgb(224, 224, 224);
}

.trade-hero-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 8vw;
    padding-top: 5vh;
}

.request-demo {
    display: flex;
    background-color: var(--aggayellow);
    width: 150px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 5px 10px 30px 8px rgba(0, 0, 0, 0.1);
    justify-content: center;
    transition: all 0.15s ease-in-out;
    margin-top: 15px;
}

.request-demo:hover {
    transform: scale(1.1);
}

.request-demo h1 {
    color: #ffffff;
    font-size: 18px;
}

.request-demo a {
    text-decoration: none;
}

.trade-about-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -100px;
    padding-top: 150px;
    padding-bottom: 150px;
    overflow: visible;
    align-items: center;
    background-color: var(--aggagrey);
}

.trade-about p {
    color: #ffff;
}

.trade-about {
    display: flex;
    padding-top: 30px;
    padding-right: 50px;
    flex-direction: column;
}

.trade-about h1 {
    color: #ffff;
}

.trade-image {
    z-index: 10;
}

.trade-image img {
    display: flex;
    max-width: 100%;
    width: 700px;
}
