@media (max-width: 500px) {
    header .logo img {
        height: 15px;
    }

    header ul li a {
        font-size: 13px;
        margin: 0 10px;
    }

    #proc img {
        top: 90vh;
        max-width: 90vw;
    }

    .card-container-2 {
        padding: 10px;
    }

    .card-text {
        min-width: 100px;
        padding: 20px;
        padding-bottom: 30px;
    }

    .card-text h2 {
        font-size: 20px;
    }

    .card-text p {
        font-size: 13px;
    }
}

@media (max-width: 920px) {
    .data_image {
        width: 350px;
        top: -10px;
    }
}

@media (max-width: 840px) {
    .team-card {
        width: 110px;
        height: 110px;
    }

    .team-title {
        width: 110px;
        height: 45px;
    }
    .team-title h3 {
        font-size: 11px;
    }
    .team-title h4 {
        font-size: 10px;
    }
}

@media (max-width: 380px) {
    #proc img {
        top: 100vh;
    }
}
